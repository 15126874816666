<template>
    <b-overlay :show="show" rounded="sm">
        <div style="padding: 30px;">
            <div class="row ">
                <b-form-checkbox size="lg"
                                 id="huhu1"
                                 name="huhu1"
                                 style="padding-right: 1em;"
                                 v-model="retweetYapabilir">
                    <p>Benim Adıma Retweet Yapabilirsiniz.</p>
                </b-form-checkbox>
            </div>
            <div class="row">
                <b-form-checkbox size="lg"
                                 id="huhu2"
                                 name="huhu2"
                                 style="padding-right: 1em;"
                                 v-model="likeYapabilir">
                    <p>Benim Adıma Like Yapabilirsiniz.</p>
                </b-form-checkbox>
            </div>
            <div class="row">
                <b-form-checkbox size="lg"
                                 id="huhu3"
                                 name="huhu3"
                                 style="padding-right: 1em;"
                                 v-model="tweetAtabilir">
                    <p>Benim Adıma Tweet Atabilirsiniz.</p>
                </b-form-checkbox>
            </div>
            <div class="row">
                <b-button class="my-3" block variant="success" @click="kaydet">KAYDET</b-button>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    import http from '@/request'
    import {mapGetters} from "vuex"
    import {KullaniciIzinleriDuzenle} from "@/api/gonullu"
    import {BasariliMesajiniGoster} from "@/messages"

    export default {
        name: "izinler",
        data() {
            return {
                show: false,
                retweetYapabilir: false,
                likeYapabilir: false,
                tweetAtabilir: false,
            }
        },
        computed: {
            ...mapGetters([
                'kisi'
            ])
        },
        methods: {
            async kaydet() {
                try {
                    this.show = true;
                    await KullaniciIzinleriDuzenle(
                        this.kisi.id,
                        this.retweetYapabilir,
                        this.likeYapabilir,
                        this.tweetAtabilir
                    )
                    BasariliMesajiniGoster("Teşekkür Ederiz!")
                }
                catch(e) {
                    alert(e)
                }
                finally {
                    setTimeout(() => {
                        this.show = false;
                    },500)
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.retweetYapabilir = this.kisi.retweetIzniVerdiMi
                this.likeYapabilir = this.kisi.likeIzniVerdiMi
                this.tweetAtabilir = this.kisi.tweetYazmaIzniVerdiMi
            })
        }
    }
</script>

<style scoped>

</style>
